<template>
  <div>
    <v-row class="matching" justify="center" align="center" style="overflow: hidden">
      <v-row class="container hero py-0">
        <v-col
          style="display: flex; align-items: center"
          :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
          justify="center">
          <v-row>
            <!-- <v-spacer></v-spacer> -->
            <v-col
              :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : 'text-center mt-16'"
              :cols="$vuetify.breakpoint.mdAndUp ? '11' : '12'">
              <v-row v-if="lang === 'KR'" class="hero-title--matching" style="display: inline-block">
                인플루언서 마케팅,<br />동일한 예산으로<br />10배의 성과를 만들어보세요.
              </v-row>
              <v-row v-if="lang === 'ENG'" class="hero-title--matching" style="display: inline-block">
                Influencer Marketing:<br />achieve 10 times<br />performance with the<br />same budget
              </v-row>
              <v-row class="mt-6 hero-subtitle--matching" style="display: inline-block" v-if="lang === 'KR'">
                인플루언서 선정부터 키워드 최적화, 성과 분석까지<br />모든 과정에 대한 최적화 솔루션을 제공합니다.
              </v-row>
              <v-row class="mt-6 hero-subtitle--matching" style="display: inline-block" v-if="lang === 'ENG'">
                An optimized solution is provided throughout <br />the entire process, including influencer
                recruitment,<br />keyword optimization, and performance analysis.
              </v-row>
              <v-row class="mt-12" style="display: block">
                <v-btn
                  rounded
                  class="white-two--text cta"
                  style="background: #f53c4b"
                  @click="showConsultDialgo = true"
                  >{{ lang === 'KR' ? '서비스 소개서 받아보기' : 'Download Service Catalog' }}</v-btn
                >
              </v-row>
              <v-row justify="center" align="center" style="max-height: 0px">
                <v-dialog
                  v-model="showConsultDialgo"
                  content-class="white-two"
                  class="rounded-xl"
                  max-width="400px"
                  :eager="true">
                  <v-card class="pa-8">
                    <v-card-text>
                      <div style="min-height: 640px">
                        <!-- <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/5XbXGopWLXB5mLZIIlRdgWpIw27FTjMr5dTpQ6CAwjlP4yQmCFCc1Cq6KXBaef9X2z"></div> -->
                        <div
                          class="pipedriveWebForms"
                          data-pd-webforms="https://webforms.pipedrive.com/f/ckzBbZPMDO9coZu86NVB7eVo6vRd8CT1M7QJEWr6lZ2KRRUUMyD26rk0tXViyj8uu7"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="text-center"
          :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
          style="display: block"
          :style="$vuetify.breakpoint.mdAndUp ? '' : 'overflow: hidden;'">
          <v-row justify="middle" align="center" class="hidden-sm-and-down" style="height: 100%; position: relative">
            <img
              src="@/assets/img/landing/matching/hero-main-back.png"
              width="616px"
              style="position: absolute; top: -32px; left: 0px"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-offset="0" />
            <img
              v-if="lang === 'KR'"
              src="@/assets/img/landing/matching/hero-main-1.png"
              width="495px"
              style="position: absolute; top: 16px; left: 72px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="100" />
            <img
              v-if="lang === 'ENG'"
              src="@/assets/img/landing/matching/hero-main-1-eng.png"
              width="495px"
              style="position: absolute; top: 16px; left: 72px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="100" />
            <img
              v-if="lang === 'KR'"
              src="@/assets/img/landing/matching/hero-main-2.png"
              width="232px"
              style="position: absolute; top: 80px; left: 384px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="300" />
            <img
              v-if="lang === 'ENG'"
              src="@/assets/img/landing/matching/hero-main-2-eng.png"
              width="232px"
              style="position: absolute; top: 80px; left: 384px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="300" />
            <img
              v-if="lang === 'KR'"
              src="@/assets/img/landing/matching/hero-main-3.png"
              width="153.5px"
              style="position: absolute; top: 108px; left: 33px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="200" />
            <img
              v-if="lang === 'ENG'"
              src="@/assets/img/landing/matching/hero-main-3-eng.png"
              width="153.5px"
              style="position: absolute; top: 108px; left: 33px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="200" />
          </v-row>
          <!-- <v-row justify="start" class="mt-4 hidden-md-and-up">
            <div class="row-image" id="hero">
              <img src="@/assets/img/landing/matching/hero-mobile.png"/>
            </div>
          </v-row> -->
          <v-row
            justify="center"
            class="mt-12 hidden-md-and-up"
            style="position: relative; min-height: 400px; max-width: 495px; margin: 0 auto">
            <img
              v-if="lang === 'KR'"
              src="@/assets/img/landing/matching/hero-main-1.png"
              width="100%"
              style="position: absolute; top: 15%; left: 0"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="100" />
            <img
              v-if="lang === 'ENG'"
              src="@/assets/img/landing/matching/hero-main-1-eng.png"
              width="100%"
              style="position: absolute; top: 15%; left: 0"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="100" />
            <img
              v-if="lang === 'KR'"
              src="@/assets/img/landing/matching/hero-main-2.png"
              width="40%"
              style="position: absolute; top: 120px; right: -32px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="300" />
            <img
              v-if="lang === 'ENG'"
              src="@/assets/img/landing/matching/hero-main-2-eng.png"
              width="40%"
              style="position: absolute; top: 120px; right: -32px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="300" />
            <img
              v-if="lang === 'KR'"
              src="@/assets/img/landing/matching/hero-main-3.png"
              width="30%"
              style="position: absolute; top: 140px; left: -24px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="200" />
            <img
              v-if="lang === 'ENG'"
              src="@/assets/img/landing/matching/hero-main-3-eng.png"
              width="30%"
              style="position: absolute; top: 140px; left: -24px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="600"
              data-aos-delay="200" />
          </v-row>
        </v-col>
      </v-row>
    </v-row>

    <v-row justify="center" align="center">
      <v-col class="text-center" :style="$vuetify.breakpoint.mdAndUp ? 'padding: 128px 0 140px' : 'padding: 64px 0'">
        <v-row class="title--matching" style="display: inline-block">
          {{ lang === 'KR' ? '슈퍼차트와 함께한 회원사' : 'Collaborating Companies with SUPERCHART' }}
        </v-row>
        <div></div>
        <v-row v-if="lang === 'KR'" class="mt-3 body--matching" style="display: inline-block">
          약 2,000개 이상의 업체와 프랜차이즈 본사에서<span class="mobile" />
          자사 솔루션을 활용하고 있습니다.
        </v-row>
        <v-row v-if="lang === 'ENG'" class="mt-3 body--matching" style="display: inline-block">
          more than 2,000 companies are utilizing<span class="mobile" />
          SUPERCHART solution.
        </v-row>
        <v-row>
          <v-row v-if="$vuetify.breakpoint.mdAndUp" class="logos-desktop">
            <div class="marquee-desktop-1 mt-10" />
            <div class="marquee-desktop-2 mt-10" />
            <div class="marquee-desktop-3 mt-10" />
            <div class="marquee-desktop-4 mt-10" />
          </v-row>
          <v-row v-if="$vuetify.breakpoint.smAndDown" class="logos-mobile mb-6">
            <div class="marquee-mobile-1 mt-8" />
            <div class="marquee-mobile-2 mt-6" />
            <div class="marquee-mobile-3 mt-6" />
            <div class="marquee-mobile-4 mt-6" />
          </v-row>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      class="bg-color--blue-gradient"
      justify="center"
      align="center"
      :style="$vuetify.breakpoint.mdAndUp ? 'overflow:hidden' : 'max-height:720px; overflow:hidden'"
      id="trigger0">
      <v-col class="text-center container pt-16">
        <v-row
          v-if="lang === 'KR'"
          class="white--text px-6"
          :class="$vuetify.breakpoint.mdAndUp ? 'title--matching' : 'title--description'"
          style="display: inline-block; font-weight: 700; word-break: keep-all">
          슈퍼차트는 분석 데이터를 바탕으로<br />최적의 인플루언서를 연결합니다
        </v-row>
        <v-row
          v-if="lang === 'ENG'"
          class="white--text px-6"
          :class="$vuetify.breakpoint.mdAndUp ? 'title--matching' : 'title--description'"
          style="display: inline-block; font-weight: 700; word-break: keep-all">
          SUPERCHART connects best influencers based on analyzed data
        </v-row>
        <div></div>
        <v-row
          v-if="lang === 'KR'"
          class="mt-4 body--description white--text px-6"
          style="display: block; word-break: keep-all">
          슈퍼차트는 인플루언서 섭외부터 성과 분석까지<br />1:1 전담매니저가 배정되어 함께 대응을 도와드립니다.
        </v-row>
        <v-row
          v-if="lang === 'ENG'"
          class="mt-4 body--description white--text px-6"
          style="display: block; word-break: keep-all">
          Starting from influencer recruit to performance analysis, one-on-one personal manager supports entire process.
        </v-row>
        <v-row
          class="hidden-sm-and-down"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-8'"
          :style="$vuetify.breakpoint.mdAndUp ? 'position: relative; height: 560px;' : 'overflow: hidden;'">
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/yt-hero1-1-desktop.png"
            width="525px"
            style="position: absolute; top: 72px; left: 257px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-offset="0" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/yt-hero1-1-desktop-eng.png"
            width="525px"
            style="position: absolute; top: 72px; left: 257px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-offset="0" />
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/yt-hero1-2-desktop.png"
            width="398px"
            style="position: absolute; top: 200px; right: -32px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="200" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/yt-hero1-2-desktop-eng.png"
            width="398px"
            style="position: absolute; top: 200px; right: -32px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="200" />
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/yt-hero1-3-desktop.png"
            width="400px"
            style="position: absolute; top: 120px; left: -32px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="100" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/yt-hero1-3-desktop-eng.png"
            width="400px"
            style="position: absolute; top: 120px; left: -32px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="100" />
          <div
            v-if="lang === 'KR'"
            class="description--circle"
            style="left: 80px; top: 32px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="700">
            <p>분석 중인<br />유튜브 채널</p>
            <span><b>300,000</b>개</span>
          </div>
          <div
            v-if="lang === 'ENG'"
            class="description--circle"
            style="left: 80px; top: 32px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="700">
            <b>300,000</b>
            <p>Channels<br />Under Analysis</p>
          </div>
          <div
            v-if="lang === 'KR'"
            class="description--circle"
            style="right: 72px; top: 120px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="850">
            <p>분석 중인<br />네이버 블로그</p>
            <span><b>3,000,000</b>개</span>
          </div>
          <div
            v-if="lang === 'ENG'"
            class="description--circle"
            style="right: 72px; top: 120px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="850">
            <b>3,000,000</b>
            <p>Blogs<br />Under Analysis</p>
          </div>

          <!-- <img class="hidden-md-and-up"
            src="@/assets/img/landing/matching/row-1-mobile-background.png" width="100%" style="display: block;padding-top:32px;max-width:360px"/> -->
          <div class="hidden-md-and-up row-image" id="row-1-background" style="height: 320px">
            <img src="@/assets/img/landing/matching/row-1-mobile-background.png" />
          </div>
          <div class="hidden-md-and-up row-image" id="row-1-foreground">
            <img src="@/assets/img/landing/matching/row-1-mobile-foreground.png" />
          </div>
        </v-row>

        <v-row class="hidden-md-and-up">
          <v-row class="my-12" style="display: flex; align-items: center; justify-content: center">
            <div
              v-if="lang === 'KR'"
              class="description--circle--mobile mx-4"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="800"
              data-aos-delay="300">
              <span><b>300,000</b>개</span>
              <p>유튜브 채널</p>
            </div>
            <div
              v-if="lang === 'ENG'"
              class="description--circle--mobile mx-4"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="800"
              data-aos-delay="300">
              <b>300,000</b>
              <p>Channels<br />Under Analysis</p>
            </div>
            <div
              v-if="lang === 'KR'"
              class="description--circle--mobile mx-4"
              style="right: 72px; top: 120px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="800"
              data-aos-delay="600">
              <span><b>3,000,000</b>개</span>
              <p>네이버 블로그</p>
            </div>
            <div
              v-if="lang === 'ENG'"
              class="description--circle--mobile mx-4"
              style="right: 72px; top: 120px"
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing="ease-out-quad"
              data-aos-duration="800"
              data-aos-delay="600">
              <b>3,000,000</b>
              <p>Blogs<br />Under Analysis</p>
            </div>
          </v-row>
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/yt-hero-mobile.png"
            style="width: 100%"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="700" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/yt-hero-mobile-eng.png"
            style="width: 100%"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="800"
            data-aos-delay="700" />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="container" :class="$vuetify.breakpoint.mdAndUp ? 'mb-8' : ''">
      <v-col cols="7" class="container--sticky justify-start hidden-sm-and-down">
        <div class="image-background">
          <div id="row2-1" :class="{ 'row2-1-eng': lang === 'ENG' }"></div>
          <div id="row2-2" :class="{ 'row2-2-eng': lang === 'ENG' }"></div>
          <div id="row2-3" :class="{ 'row2-3-eng': lang === 'ENG' }"></div>
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12" :class="$vuetify.breakpoint.mdAndUp ? 'pl-16' : ''">
        <!-- <div id="trigger2_1">
          <div class="container--description" :style="$vuetify.breakpoint.mdAndUp?'':'height:220px;padding-top: 48px'">
            <div class="subtitle--matching subtitle--youtube">
              유튜브
            </div>
            <div class="title--matching" style="display: inline-block">
              급성장 중인 채널에 대한<br>실시간 분석
            </div>
            <div class="mt-4 body--matching" style="display: inline-block">
              슈퍼차트의 유튜브 채널 성장도 분석은 급격하게<br>성장하는 채널을 실시간으로 빠르게 찾아냅니다.
            </div>
          </div>
        </div> -->
        <div class="container--description" id="trigger2_1">
          <div class="subtitle--description subtitle--naver">{{ lang === 'KR' ? '네이버 블로그' : 'Naver Blog' }}</div>
          <div v-if="lang === 'KR'" class="mt-1 title--description" style="display: inline-block">
            단 4명의 블로거로<br />상위노출을?
          </div>
          <div v-if="lang === 'ENG'" class="mt-1 title--description" style="display: inline-block">
            Top exposure for<br />just 4 influencers
          </div>
          <div v-if="lang === 'KR'" class="mt-2 body--description" style="display: inline-block">
            슈퍼차트에서는 메인키워드를 4명 이내의 블로거로<span class="desktop" />
            통합검색 3위 내에 상위노출 시킵니다.
          </div>
          <div v-if="lang === 'ENG'" class="mt-2 body--description" style="display: inline-block">
            SUPERCHART makes top exposure possible for<span class="desktop" />
            main keyword in the top 3 of integrated
            <span class="desktop" />
            searches by collaborating with just 4 bloggers
            <span class="desktop" />
            or less.
          </div>
          <div class="hidden-sm-and-down container--indicator">
            <div class="indicator--naver"></div>
            <div class="indicator--disabled"></div>
            <div class="indicator--disabled"></div>
          </div>
          <div class="hidden-md-and-up image-background--mobile mt-2" id="mobile">
            <div id="row2-1-mobile" :class="{ 'row2-1-mobile-eng': lang === 'ENG' }"></div>
          </div>
        </div>
        <div class="container--description" id="trigger2_2">
          <div class="subtitle--description subtitle--naver">{{ lang === 'KR' ? '네이버 블로그' : 'Naver Blog' }}</div>
          <div v-if="lang === 'KR'" class="mt-1 title--description" style="display: inline-block">
            슈퍼차트만의 퀄리티 높은<br />인플루언서 라인업
          </div>
          <div v-if="lang === 'ENG'" class="mt-1 title--description" style="display: inline-block">
            SUPERCHART’s high-quality<br />line up of influencers
          </div>
          <div v-if="lang === 'KR'" class="mt-2 body--description" style="display: inline-block">
            네이버 랭킹 상위 100위 이내 42명의 블로거 보유, 네이버 전체 1위 블로거도 슈퍼차트에서 활동 중입니다.
          </div>
          <div v-if="lang === 'ENG'" class="mt-2 body--description" style="display: inline-block">
            SUPERCHART possesses 42 bloggers in the top 100 in Naver rankings, and the number 1 blogger overall on Naver
            is also active in SUPERCHART
          </div>
          <div class="hidden-sm-and-down container--indicator">
            <div class="indicator--disabled"></div>
            <div class="indicator--naver"></div>
            <div class="indicator--disabled"></div>
          </div>
          <div class="hidden-md-and-up image-background--mobile mt-2" id="mobile">
            <div id="row2-2-mobile" :class="{ 'row2-2-mobile-eng': lang === 'ENG' }"></div>
          </div>
        </div>
        <div class="container--description" id="trigger2_3">
          <div class="subtitle--description subtitle--naver">{{ lang === 'KR' ? '네이버 블로그' : 'Naver Blog' }}</div>
          <div v-if="lang === 'KR'" class="mt-1 title--description" style="display: inline-block">
            자체 블로그 지수 분석을 통해<br />상위노출을 잡아냅니다
          </div>
          <div v-if="lang === 'ENG'" class="mt-1 title--description" style="display: inline-block">
            Captures top exposure through<br />internal blog index analysis
          </div>
          <div v-if="lang === 'KR'" class="mt-2 body--description" style="display: inline-block">
            슈퍼차트는 분석 데이터를 바탕으로 최적의 인플루언서를 연결합니다. 섭외부터 성과 분석까지 1:1 전담매니저가
            배정되어 함께 대응을 도와드립니다.
          </div>
          <div v-if="lang === 'ENG'" class="mt-2 body--description" style="display: inline-block">
            SUPERCHART connects you with the optimal influencer based on analysis data. From recruiting to performance
            analysis, a 1:1 dedicated manager will be assigned to help you.
          </div>
          <div class="hidden-sm-and-down container--indicator">
            <div class="indicator--disabled"></div>
            <div class="indicator--disabled"></div>
            <div class="indicator--naver"></div>
          </div>
          <div class="hidden-md-and-up image-background--mobile mt-2" id="mobile">
            <div id="row2-3-mobile" :class="{ 'row2-3-mobile-eng': lang === 'ENG' }"></div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="container mb-8">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
        <div id="trigger1_1">
          <!-- <div class="container--description" ref="descriptionRow" :style="$vuetify.breakpoint.mdAndUp?'':'height:220px;padding-top: 48px'">
            <div class="subtitle--matching subtitle--naver">
              네이버 블로그
            </div>
            <div class="title--matching" style="display: block">
              상위노출 블로그에 대한<br>강력한 분석
            </div>
            <div class="mt-4 body--matching" style="display: block">
              슈퍼차트의 블로그 지수 분석은 특정 키워드에 대해<span class="desktop"></span> 상위노출이 가능한 블로거를 정확히 찾아냅니다.
            </div>
          </div> -->
          <div class="container--description" ref="descriptionRow" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-8'">
            <div class="subtitle--description subtitle--youtube">{{ lang === 'KR' ? '유튜브' : 'YouTube' }}</div>
            <div v-if="lang === 'KR'" class="mt-1 title--description" style="display: block">
              단 300만원으로<br />2-30만 유튜버 섭외를?
            </div>
            <div v-if="lang === 'ENG'" class="mt-1 title--description" style="display: block">
              $3,000 is enough to<br />recruit 2-300k YouTubers
            </div>
            <div v-if="lang === 'KR'" class="mt-2 body--description" style="display: block">
              슈퍼차트에서는 전담매니저가 가성비 채널 발굴부터<br />
              광고비 네고까지 모두 도와드립니다.
            </div>
            <div v-if="lang === 'ENG'" class="mt-2 body--description" style="display: block">
              At SUPERCHART, a dedicated manager will asist you with discovering cost-effective channels and negotiating
              advertising costs.
            </div>
            <div class="hidden-sm-and-down container--indicator">
              <div class="indicator--youtube"></div>
              <div class="indicator--disabled"></div>
              <div class="indicator--disabled"></div>
            </div>
            <div class="hidden-md-and-up image-background--mobile mt-2" id="mobile">
              <div id="row1-1-mobile" :class="{ 'row1-1-mobile-eng': lang === 'ENG' }"></div>
            </div>
          </div>
        </div>
        <div class="container--description" id="trigger1_2">
          <div class="subtitle--description subtitle--youtube">{{ lang === 'KR' ? '유튜브' : 'YouTube' }}</div>
          <div v-if="lang === 'KR'" class="mt-1 title--description" style="display: block">
            규모 대비 조회수가 높은<br />알짜 채널을 찾아냅니다.
          </div>
          <div v-if="lang === 'ENG'" class="mt-1 title--description" style="display: block">
            Find channels with<br />a high number of views<br />relative to their size
          </div>
          <div v-if="lang === 'KR'" class="mt-2 body--description" style="display: block">
            유튜브 채널별 최근 영상 성과만을 집중적으로 분석해<br />규모 대비 높은 조회수를 달성한 유튜버를 찾아냅니다.
          </div>
          <div v-if="lang === 'ENG'" class="mt-2 body--description" style="display: block">
            By analyzing the recent video performance of YouTube channels, we identify YouTubers who have achieved a
            high number of views relative to their channel’s size.
          </div>
          <div class="hidden-sm-and-down container--indicator">
            <div class="indicator--disabled"></div>
            <div class="indicator--youtube"></div>
            <div class="indicator--disabled"></div>
          </div>
          <div class="hidden-md-and-up image-background--mobile mt-2" id="mobile">
            <div id="row1-2-mobile" :class="{ 'row1-2-mobile-eng': lang === 'ENG' }"></div>
          </div>
        </div>
        <div class="container--description" id="trigger1_3">
          <div class="subtitle--description subtitle--youtube">{{ lang === 'KR' ? '유튜브' : 'YouTube' }}</div>
          <div v-if="lang === 'KR'" class="mt-1 title--description" style="display: block">
            우리 회사에 꼭 맞는<br />유튜버는 누구일까?
          </div>
          <div v-if="lang === 'ENG'" class="mt-1 title--description" style="display: block">
            Who’s the ultimate<br />YouTube star for our<br />company?
          </div>
          <div v-if="lang === 'KR'" class="mt-2 body--description" style="display: block">
            유튜브 채널의 카테고리와 함께 시청 연령 ∙ 성별 ∙ 시청 국가 통계를 통해 정확한 타겟 오디언스를 설정합니다.
          </div>
          <div v-if="lang === 'ENG'" class="mt-2 body--description" style="display: block">
            Set a precise target audience based on the YouTube channel’s categories and viewer’s age, gender, and
            location statistics.
          </div>
          <div class="hidden-sm-and-down container--indicator">
            <div class="indicator--disabled"></div>
            <div class="indicator--disabled"></div>
            <div class="indicator--youtube"></div>
          </div>
          <div class="hidden-md-and-up image-background--mobile mt-2" id="mobile">
            <div id="row1-3-mobile" :class="{ 'row1-3-mobile-eng': lang === 'ENG' }"></div>
          </div>
        </div>
      </v-col>
      <v-col cols="8" class="container--sticky justify-end hidden-sm-and-down">
        <div class="image-background">
          <div id="row1-1" :class="{ 'row1-1-eng': lang === 'ENG' }"></div>
          <div id="row1-2" :class="{ 'row1-2-eng': lang === 'ENG' }"></div>
          <div id="row1-3" :class="{ 'row1-3-eng': lang === 'ENG' }"></div>
        </div>
      </v-col>
    </v-row>

    <!-- <v-row justify="center" align="center" :style="$vuetify.breakpoint.mdAndUp ? 'margin-bottom:128px': 'margin: 48px 0'">
      <v-col class="text-center container pt-16">
        <v-row class="title--matching" style="display: inline-block">
          섭외부터 성과 분석까지,<br>1:1 전담 매니저가 함께합니다.
        </v-row>
        <div></div>
        <v-row class="mt-4 body--matching" style="display: inline-block">
          최적의 인플루언서 선정과 마케팅 소재 최적화, 원고/기획안 검수, <br>성과 추적까지 1:1 전담 매니저가 광고 진행을 도와드립니다.
        </v-row>
        <v-row :style="$vuetify.breakpoint.mdAndUp? '': 'overflow: hidden;'" 
                :class="$vuetify.breakpoint.mdAndUp? 'mt-16': 'mt-4 mb-4 px-6'"
                align-content="center"
                justify="center">
          <v-col class="manager-icon-container" cols=4
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-quad'
            data-aos-delay='0'>
            <div :class="$vuetify.breakpoint.mdAndUp ? 'manager-icon-circle' : 'manager-icon-circle--mobile'">
              <img src="../../assets/img/landing/matching/manager-icon-1.png"
                   style="width: 86%;height:86%"/>
            </div>
            <p class="body--matching" style="display:block">
              데이터 기반의<span class="mobile"></span> 인플루언서 선정
            </p>
          </v-col>
          <v-col class="manager-icon-container" cols=4
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-quad'
            data-aos-delay='150'>
            <div :class="$vuetify.breakpoint.mdAndUp ? 'manager-icon-circle' : 'manager-icon-circle--mobile'">
              <img src="../../assets/img/landing/matching/manager-icon-2.png"
                   style="width: 86%;height:86%"/>
            </div>
            <p class="body--matching" style="display:block">
              마케팅 소재<span class="mobile"></span> 최적화
            </p>
          </v-col>
          <v-col class="manager-icon-container" cols=4
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-quad'
            data-aos-delay='300'>
            <div :class="$vuetify.breakpoint.mdAndUp ? 'manager-icon-circle' : 'manager-icon-circle--mobile'">
              <img src="../../assets/img/landing/matching/manager-icon-3.png"
                   style="width: 86%;height:86%"/>
            </div>
            <p class="body--matching" style="display:block">
              원고/기획안 검수<span class="mobile"></span> 및 성과 추적
            </p>
          </v-col>
          
          
        </v-row>
      </v-col>
    </v-row> -->

    <v-row
      justify="center"
      align="center"
      :class="$vuetify.breakpoint.smAndDown ? 'mt-12' : ''"
      style="overflow: hidden">
      <v-col class="text-center container">
        <v-row v-if="lang === 'KR'" class="title--matching" style="display: inline-block">
          진행되는 캠페인별 성과는 <span class="mobile" />정확하게 측정됩니다.
        </v-row>
        <v-row v-if="lang === 'ENG'" class="title--matching" style="display: inline-block">
          We meticulously track the<br />effectiveness of every ongoing camaign.
        </v-row>
        <div></div>
        <v-row v-if="lang === 'KR'" class="mt-4 body--matching" style="display: inline-block">
          조회수, 반응수를 비롯해 구매링크 클릭 수, CPV, CPC,
          <span class="mobile" />CTR 등 <span class="desktop" />광고 효율을 명확히 측정할 수 있는 지표를
          <span class="mobile" />제공합니다.
        </v-row>
        <v-row v-if="lang === 'ENG'" class="mt-4 body--matching" style="display: inline-block">
          We offer key metrics to measure advertising efficiency, including<span class="desktop" />views, reactions,
          purchase link clicks, CPV, CPC, and CTR.
        </v-row>
        <v-row class="mt-8 hidden-sm-and-down" style="position: relative; max-width: 1040px; min-height: 618px">
          <img
            src="@/assets/img/landing/matching/report-back.png"
            width="799px"
            style="position: absolute; top: 96px; left: 120px"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-offset="0" />
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/report-1.png"
            width="728px"
            style="position: absolute; top: 24px; left: 24px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="400"
            data-aos-delay="200" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/report-1-eng.png"
            width="728px"
            style="position: absolute; top: 24px; left: 24px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="400"
            data-aos-delay="200" />
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/report-2.png"
            width="614px"
            style="position: absolute; left: 426px; top: 307px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="400"
            data-aos-delay="300" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/report-2-eng.png"
            width="614px"
            style="position: absolute; left: 426px; top: 307px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="400"
            data-aos-delay="300" />
        </v-row>
        <!-- <v-row class="mt-8 hidden-md-and-up" style="position: relative; max-width: 1040px; min-height: 618px">
          <img
            src="@/assets/img/landing/matching/report-back.png" width="799px" 
            style="position: absolute; top:96px; left: 120px;" 
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing='ease-out-quad'
            data-aos-offset='0'/>
          <img
            src="@/assets/img/landing/matching/report-1.png" width="728px" 
            style="position: absolute; top:24px; left: 24px;" 
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-quad'
               data-aos-duration="400"
            data-aos-delay='200'/>
          <img
            src="@/assets/img/landing/matching/report-2.png" width="614px" 
            style="position: absolute; left: 426px; top:307px;" 
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-quad'
               data-aos-duration="400"
            data-aos-delay='300'/>
        </v-row>  -->
        <v-row
          class="mt-12 hidden-md-and-up"
          style="position: relative; min-height: 400px; max-width: 495px; margin: 0 auto">
          <img
            src="@/assets/img/landing/matching/report-back.png"
            width="400px"
            height="300px"
            style="position: relative; margin: 56px auto 0"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="600"
            data-aos-delay="100" />
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/report-1.png"
            width="80%"
            style="position: absolute; top: 16px; left: -16px; min-width: 320px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="600"
            data-aos-delay="300" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/report-1-eng.png"
            width="80%"
            style="position: absolute; top: 16px; left: -16px; min-width: 320px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="600"
            data-aos-delay="300" />
          <img
            v-if="lang === 'KR'"
            src="@/assets/img/landing/matching/report-2.png"
            width="80%"
            style="position: absolute; top: 172px; right: -24px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="600"
            data-aos-delay="200" />
          <img
            v-if="lang === 'ENG'"
            src="@/assets/img/landing/matching/report-2-eng.png"
            width="80%"
            style="position: absolute; top: 172px; right: -24px"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing="ease-out-quad"
            data-aos-duration="600"
            data-aos-delay="200" />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="bg-color--gray" justify="center" align="center">
      <v-col
        class="text-center"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 200px 0px 128px;' : 'padding: 48px 0px;'">
        <v-row class="title--matching" style="display: inline-block">
          {{ lang === 'KR' ? '슈퍼차트 캠페인 진행 사례' : 'SUPERCHART campaign progress examples' }}
        </v-row>
        <div></div>
        <v-row v-if="lang === 'KR'" class="mt-4 body--matching" style="display: inline-block">
          섭외부터 성과 분석까지, 1:1 전담 매니저가 집중 관리해드립니다.
        </v-row>
        <v-row v-if="lang === 'ENG'" class="mt-4 body--matching" style="display: inline-block">
          From recruiting to performance analysis, a 1:1 dedicated manager will provide intensive management.
        </v-row>
        <slick ref="slick" :options="slickOptions">
          <div class="item" v-for="item in exampleItems" :key="item.img">
            <a :href="item.link" target="_blank">
              <div
                class="top"
                :style="{
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%), url(' +
                    require(`@/assets/img/landing/companies/${item.img}.png`) +
                    ')',
                  backgroundSize: 'cover',
                }"></div>
              <div class="bottom">
                <div class="head">
                  <span>
                    {{ lang === 'KR' ? item.category : item.categoryEng }}
                  </span>
                  <img :src="require(`@/assets/img/landing/companies/icons/${item.logo}.png`)" alt="logo" />
                </div>
                <div class="contents">
                  <div>
                    {{ lang === 'KR' ? item.contentTitle : item.contentTitleEng }}
                  </div>
                  <div class="description">
                    {{ lang === 'KR' ? item.contentDescription : item.contentDescriptionEng }}
                  </div>
                </div>
                <div class="date">
                  {{ item.date }}
                </div>
              </div>
            </a>
          </div>
          <!-- <a class="item" :href="data.link" target="_blank" v-for="data in filters[selected].datas" :key="data.title" >
            <v-card :max-width="$vuetify.breakpoint.mdAndUp? 300 : 160" :max-height="$vuetify.breakpoint.mdAndUp? 304 : 180" 
                    flat style="cursor:pointer;background-color: rgba(255,255,255,0);">
              <div style="position: relative;border-radius: 4px;overflow: hidden;">
                <div style="position: absolute;width:100%;height:100%;z-index: 10;background: linear-gradient(180deg, rgba(176, 176, 176, 0.05) 0%, rgba(26, 26, 26, 0.2) 100%);"></div>
                <img :src="data.image" 
                    :width="$vuetify.breakpoint.mdAndUp? 300 : 160" 
                    :height="$vuetify.breakpoint.mdAndUp? 180 : 92" 
                    style="top:0;border-radius: 4px; object-fit: cover;"/>
              </div>
              <div class="mt-3" style="text-align: left;color:#21232E; font-weight:600; word-break:keep-all; -webkit-line-clamp: 2;text-overflow: ellipsis;"
                  :style="$vuetify.breakpoint.mdAndUp ? 'font-size:18px; line-height:22px;height:' : 'font-size:11px; line-height:13px;'">
                {{data.title}}
              </div>
              <div class="mt-4" style="text-align: left;color:#757575; font-weight:400; word-break:keep-all"
                  :style="$vuetify.breakpoint.mdAndUp ? 'font-size:14px; line-height:17px;' : 'font-size:11px; line-height:13px;'">
                {{data.category}}
              </div>
              <div class="mt-1" style="text-align: left;color:#C2C2C2; font-weight:300; word-break:keep-all"
                  :style="$vuetify.breakpoint.mdAndUp ? 'font-size:14px; line-height:17px;' : 'font-size:10px; line-height:13px;'">
                {{data.uploadedAt}}
              </div>
              
            </v-card>
          </a> -->
        </slick>
      </v-col>
    </v-row>

    <!-- <v-row style="background:#FCFBFA">
      <v-spacer></v-spacer>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '9' : '12'" :class="$vuetify.breakpoint.mdAndUp ? '': 'text-center'">
        <v-row class="title--matching mt-16" style="display: inline-block">
          상위노출 가능성이 높은<br>블로거만 골라보세요.
        </v-row>
        <v-row class="mt-4" style="justify-content:center">
          <v-tabs v-model="tab" color="#1E69D4" background-color=rgba(1,1,1,0) active-class="tabs-active" class="hidden-sm-and-down">
            <v-tab :ripple="false" class="tabs">키워드 검색</v-tab>
            <v-tab class="tabs">영향력 확인</v-tab>
            <v-tab class="tabs">원고 수정</v-tab>
            <v-tab class="tabs">성과 추적</v-tab>
          </v-tabs>
          <v-tabs v-model="tab" color="#1E69D4" background-color=rgba(1,1,1,0) active-class="tabs-active" class="hidden-md-and-up" centered>
            <v-tab :ripple="false" class="tabs">키워드 검색</v-tab>
            <v-tab class="tabs">영향력 확인</v-tab>
            <v-tab class="tabs">원고 수정</v-tab>
            <v-tab class="tabs">성과 추적</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-10" style="background-color:rgba(1,1,1,0)">
            <v-tab-item>
              <v-row>
                <v-col class="body--matching" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" style="font-size:18px">
                  원하는 키워드만 검색하면 끝!<br>최적의 블로거를 추천해드립니다.
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                  <img class="hidden-sm-and-down"
                      src="@/assets/img/landing/matching/tab1_tinified-desktop.png" width="100%" style="display: inline-block"/>
                  <img class="hidden-md-and-up"
                  src="@/assets/img/landing/matching/tab1_tinified-mobile.png" width="92%" style="display: inline-block;padding-top:32px;max-width:360px"/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col class="body--matching" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" style="font-size:18px">
                  명확한 기준으로 블로거를 선정하세요.<br>상위노출 비율부터 상위노출 지수까지,<span class="hidden-md-and-up" style="display:block"/> 블로거의 영향력을 자세히 확인하세요.
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                  <img class="hidden-sm-and-down"
                      src="@/assets/img/landing/matching/tab2_tinified-desktop.png" width="100%" style="display: inline-block"/>
                  <img class="hidden-md-and-up"
                  src="@/assets/img/landing/matching/tab2_tinified-mobile.png" width="92%" style="display: inline-block;padding-top:32px;max-width:360px"/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col class="body--matching" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" style="font-size:18px">
                  게시글이 마음에 들지 않으세요?<br>원고 수정을 요청할 수 있습니다.
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                  <img class="hidden-sm-and-down"
                      src="@/assets/img/landing/matching/tab3_tinified-desktop.png" width="100%" style="display: inline-block"/>
                  <img class="hidden-md-and-up"
                  src="@/assets/img/landing/matching/tab3_tinified-mobile.png" width="92%" style="display: inline-block;padding-top:32px;max-width:360px"/>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col class="body--matching" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" style="font-size:18px">
                  마케팅 성과는 정확하게 추적해야죠.<br>조회 수, 클릭 수, 노출 순위 등<span class="hidden-md-and-up" style="display:block"/> 마케팅 성과를 정확하게 확인하세요.
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
                  <img class="hidden-sm-and-down"
                      src="@/assets/img/landing/matching/tab4_tinified-desktop.png" width="100%" style="display: inline-block"/>
                  <img class="hidden-md-and-up"
                  src="@/assets/img/landing/matching/tab4_tinified-mobile.png" width="92%" style="display: inline-block;padding-top:32px;max-width:360px"/>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row> -->

    <v-row justify="center" align="center" style="background-color: #1e69d4">
      <v-col class="text-center pt-16">
        <v-row class="title--matching white-two--text" style="display: inline-block; font-weight: 700">
          {{ lang === 'KR' ? '서비스 소개서 다운로드' : 'Download Service Catalog' }}
        </v-row>
        <v-row v-if="lang === 'KR'" class="body--matching white-two--text mt-3" style="display: block">
          슈퍼차트에 대해 더 알아보고 싶으시다면<span class="mobile"></span> 서비스 소개서를 확인해보세요.
        </v-row>
        <v-row v-if="lang === 'ENG'" class="body--matching white-two--text mt-3" style="display: block">
          To find out more about SUPERCHART,<span class="mobile"></span>
          check out the service catalog.
        </v-row>
        <v-row class="mb-16 mt-6" style="display: block">
          <v-col justify="center" align="center">
            <div
              class="pipedrive-request pa-8 ma-4"
              :style="$vuetify.breakpoint.mdAndUp ? 'width: 460px' : 'min-width: 320px'">
              <div
                class="pipedriveWebForms"
                data-pd-webforms="https://webforms.pipedrive.com/f/6zds60ZwFXnBWuKYkf2avxHtFCuXQttM9VoUdAFtfuN9OiyYEsLm6mDnavtIq72RRF"></div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import References from '@/components/landing/References'
import Slick from 'vue-slick';

export default {
  name: 'Matching',
  components: {
    Slick,
  },
  data() {
    return {
      lang: 'KR',
      tab: null,
      image:
        window.innerWidth > 1039
          ? 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fchart-desktop.png?alt=media&token=e9a9baae-a6ae-4017-b9d1-3229048483ac'
          : 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fchart-mobile.png?alt=media&token=4a9198e0-65fd-4882-a1e3-a3b2f3e483ed',
      showConsultDialgo: false,
      currentItem: 0,
      filters: [
        {
          name: '맛집',
          datas: [
            {
              title: '유튜브 체험단 협찬으로 매출 폭발시키기(CPV4.6원)',
              category: 'Success Case · Youtube',
              uploadedAt: '2023. 01. 31.',
              link: 'https://brunch.co.kr/@mayacrew/36',
              subTitle: '광고예산 300만원 / 조회수 65만 회 / 일매출 신기록 2번 갱신',
              contents:
                '슈퍼차트는 작년 11월부터 유튜브 채널을 출시하여 마케팅의 전 과정을 무료로 대행하고 있습니다. 아래는 실제 저희 측에서 진행하였던 유튜브 영상입니다.',
              image: require('@/assets/img/landing/matching/blogArticles/ByWmAZMTiZrlLRM84Z3ByD8fyfw.png'),
            },
            {
              title: "인플루언서 마케팅 트렌드 '숏폼', 유튜브숏츠 정복사례",
              category: 'Success Case · Youtube',
              uploadedAt: '2023. 02. 09.',
              link: 'https://brunch.co.kr/@mayacrew/41',
              subTitle: 'CPV 38원 | 조회수 7.8만 | 틱톡 | 인스타릴스',
              contents:
                '슈퍼차트는 작년 11월부터 유튜브 채널을 출시하여 마케팅의 전 과정을 무료로 대행하고 있습니다. 그런데 최근들어, Youtube Shorts(숏츠)에서도 심상치 않은 성과를 보이고 있습니다.',
              image: require('@/assets/img/landing/matching/blogArticles/Tjkr-ewcvsgBVQIfL85CPSVrbVU.jpg'),
            },
            {
              title: '유튜브에서 유튜버 협찬으로 CPV 13원 만들기',
              category: 'Success Case · Youtube',
              uploadedAt: '2022. 10. 13.',
              link: 'https://brunch.co.kr/@mayacrew/19',
              subTitle: '인플루언서 마케팅 | 유튜버 협찬 효과 얼마나 있을까?',
              contents:
                '인플루언서 마케팅에는 보통 네이버 블로그, 유튜브, 인스타그램 이렇게 세 종류의 소셜미디어를 활용하게 되는데요, 실제로 슈퍼차트 플랫폼을 운영하면서 최근의 인플루언서 마케팅의 성과를 비교해보면,',
              image: require('@/assets/img/landing/matching/blogArticles/RV_ambnljztfHxfXc7xnbjA6QKw.png'),
            },
            {
              title: '블로그체험단으로 금융/부동산 인플루언서 마케팅 점령하기',
              category: 'Success Case · Blog',
              uploadedAt: '2023. 02. 06.',
              link: 'https://brunch.co.kr/@mayacrew/39',
              subTitle: 'CPV 20원대 | VIEW 탭, 인플루언서 탭 1, 2, 3, 4위',
              contents:
                '최근 저희 슈퍼차트가 <금융/부동산> 카테고리에서 심상치 않은 성장세를 보이고 있습니다. <금융/부동산> 테마는 상위노출만 되면 성과가 보장될 만큼 검색량이 많고, 그만큼 피터지는 경쟁입니다.',
              image: require('@/assets/img/landing/matching/blogArticles/41_cznRQCj05NM2pBGA6wrwhkwU.png'),
            },
            {
              title: '상위노출 1타 3피, 블로그마케팅의 해답/월검색 22만',
              category: 'Success Case · Blog',
              uploadedAt: '2023. 02. 16.',
              link: 'https://brunch.co.kr/@mayacrew/45',
              subTitle: "키워드 '버팀목전세자금대출'|인플루언서 마케팅 성공사례 | CPV 22원",
              contents:
                "저희가 월간 검색량 220,000(22만) 키워드, '버팀목 전세자금대출'에서 상위노출 1위를 차지했습니다!",
              image: require('@/assets/img/landing/matching/blogArticles/yWVzq5fc9EZ31cK5lnC_Wi0aQ3Q.png'),
            },
            {
              title: '블로그의 미래, AI로 고품질 포스팅 보장받기|챗GPT',
              category: 'Insights',
              uploadedAt: '2023. 02. 14.',
              link: 'https://brunch.co.kr/@mayacrew/43',
              subTitle: 'GPT-3 다빈치모델 | 인플루언서 마케팅 | 네이버 블로그 | 슈퍼차트',
              contents: '슈퍼차트가 GPT-3 다빈치모델을 이용한 블로그 포스트 자동 생성 기능을 출시하였습니다!',
              image: require('@/assets/img/landing/matching/blogArticles/tHn2WsMMZ2ndg5Nx_IqkSExz4wo.jpeg'),
            },
            {
              title: "보조배터리' 네이버 상위노출, 블로그 체험단 성공사례",
              category: 'Success Case · Blog',
              uploadedAt: '2023. 02. 08.',
              link: 'https://brunch.co.kr/@mayacrew/40',
              subTitle: '월간 검색량 24.6만 | 인플탭 3위 노출 사례 | 인플루언서 마케팅',
              contents:
                "오늘은 키워드 '보조배터리'에서 네이버 인플루언서 탭 3위에 달성한 사례를 소개해 드리고자 합니다! 이제는 현대인의 신체의 일부가 된 스마트폰, 그에 걸맞게 '보조배터리'에 대한 관심이 끊이질 않고 있습니다.",
              image: require('@/assets/img/landing/matching/blogArticles/LGExRWvTXv3kPXfnPzusc2JdqUg.jpeg'),
            },
            {
              title: '블로그체험단 마케팅, 상위노출 타율 100% 달성 비결',
              category: 'Success Case · Blog',
              uploadedAt: '2023. 02. 10.',
              link: 'https://brunch.co.kr/@mayacrew/42',
              subTitle: "여성패딩' 키워드 상위노출 성공사례 | 네이버 VIEW탭 2, 3위",
              contents:
                "안녕하세요. 인플루언서 마케팅 최적화 솔루션을 제공하는 슈퍼차트입니다. 오늘은 키워드 '여성패딩'에서 네이버 VIEW 탭 2, 3위를 차지한 사례를 소개해 드리고자 합니다!",
              image: require('@/assets/img/landing/matching/blogArticles/K3m-pYzMTIDucqTY5lhbPYp8Zkc.png'),
            },
            {
              title: '유튜브 성장을 위한 무한한 아이디어, AI와 함께하세요',
              category: 'Insights',
              uploadedAt: '2023. 02. 17.',
              link: 'https://brunch.co.kr/@mayacrew/46',
              subTitle: '유튜브 기획에 막히는 유튜버들을 위한 AI 비서, GPT-3|인플루언서',
              contents:
                "안녕하세요, 인플루언서 마케팅 플랫폼 '슈퍼차트'입니다. 저희 슈퍼차트가 GPT-3 다빈치모델을 이용한 블로그 포스트 자동 생성 기능을 출시하였다는 소식, 전해드렸었는데요.",
              image: require('@/assets/img/landing/matching/blogArticles/eeGEDPz0VXqpxA3eE4d1hYI8Usc.jpg'),
            },
            {
              title: '아직도 "인플루언서 마케팅"을 이용하지 않고 있나요?',
              category: 'Insights',
              uploadedAt: '2022. 10. 05.',
              link: 'https://brunch.co.kr/@mayacrew/18',
              subTitle: '떠오르는 신흥 마케팅 영역, 인플루언서 마케팅',
              contents:
                '인플루언서의 영향력은 그 이름 그대로, 나날이 커져가고 있습니다. 인플루언서는 SNS, 소셜미디어의 성장과 함께 디지털 세대에 맞추어 빠른 속도로 성장했습니다. 이렇게 커져가는 영향력을 바탕으로 인플루언서를 내세운 "인플루언서 마케팅"은 이제 마케팅 영역에서 빼놓을 수 없는 중요한 전략이 되었습니다.',
              image: require('@/assets/img/landing/matching/blogArticles/vvpXOM8aw06T4ntRjKMhtaLRgp4.jpg'),
            },
            {
              title: "클O스101'은 블로그 마케팅을 어떻게할까?",
              category: 'Success Case · Blog',
              uploadedAt: '2022. 09. 19.',
              link: 'https://brunch.co.kr/@mayacrew/13',
              subTitle: '요즘 뜨는 기업들이 찾는다는 블로그 마케팅, "슈퍼차트',
              contents:
                '위드코로나 시대가 열린 요즘, 마케팅에 더욱 박차를 가해야 할 적절한 시기입니다! 상품 판매하시거나 요식업 등 장소 운영하시는 업주분들 중에 네이버 블로그를 활용해서 마케팅하시는 분들 엄청 많으신데요.',
              image: require('@/assets/img/landing/matching/blogArticles/E6m4PJLR4izYs3wB9BKQrZYG82g.png'),
            },
            {
              title: '박터졌던 설날 블로그 마케팅, 상위노출 차지한 비결?',
              category: 'Success Case · Blog',
              uploadedAt: '2023. 01. 31.',
              link: 'https://brunch.co.kr/@mayacrew/35',
              subTitle: '마케팅 전략 | 블로그체험단 | 파워블로거',
              contents:
                '매년 새해가 다가오면 온정을 나누며 행복을 기원할 뿐만 아니라 설선물로 마음을 표하기도 합니다. 특히 코로나19 장기화로 인해 명절 선물에 대한 수요가 증가하였는데요, 식품업체들은 계묘년을 맞아 설날 마케팅/상위노출 쟁탈에 열을 올렸습니다.',
              image: require('@/assets/img/landing/matching/blogArticles/QO_vG11hsqwkqUqi99zHF82WYT8.jpeg'),
            },
            {
              title: '네이버 블로그 마케팅 상위노출을 위한 툴',
              category: 'Insights',
              uploadedAt: '2021. 01. 26.',
              link: 'https://brunch.co.kr/@mayacrew/8',
              subTitle: '슈퍼차트 편의 기능 5가지',
              contents:
                '마야크루가 개발한 블로그 마케팅 플랫폼 슈퍼차트는 블로거 탐색, 진행, 원고 모니터링, 성과 측정까지 블로그 마케팅의 전 과정을 빈틈없이 지원합니다.',
              image: require('@/assets/img/landing/matching/blogArticles/ltMuonvihySF5KWmerf8rQ7pI00.png'),
            },
          ],
        },
      ],
      exampleItems: [
        {
          img: '위영양제',
          category: '건강기능식품',
          categoryEng: 'nutritional supplement',
          logo: 'Blog',
          contentTitle: '위영양제',
          contentTitleEng: 'digestive nutrients',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'Top exposure keyword example',
          date: '2022. 11. 14',
          link: 'https://brunch.co.kr/@mayacrew/29',
        },
        {
          img: '슈퍼싱글침대',
          category: '가구',
          categoryEng: 'furniture',
          logo: 'Blog',
          contentTitle: '슈퍼싱글침대',
          contentTitleEng: 'Super Single Mattress',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'Top exposure keyword example',
          date: '2022. 11. 10',
          link: 'https://brunch.co.kr/@mayacrew/27',
        },
        {
          img: '버팀목전세자금대출',
          category: '금융',
          categoryEng: 'finance',
          logo: 'Blog',
          contentTitle: '버팀목 전세자금대출',
          contentTitleEng: 'Loan Assistance Program',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'Top exposure keyword example',
          date: '2023. 02. 16',
          link: 'https://brunch.co.kr/@mayacrew/45',
        },
        {
          img: '횟집마케팅사례',
          category: '음식점',
          categoryEng: 'restaurant',
          logo: 'Youtube',
          contentTitle: '매장 방문형',
          contentTitleEng: 'Place (visiting type)',
          contentDescription: '횟집 마케팅 사례',
          contentDescriptionEng: 'Sample of sashimi example',
          date: '2023. 01. 31',
          link: 'https://brunch.co.kr/@mayacrew/36',
        },
        {
          img: '가습기',
          category: '가전',
          categoryEng: 'home appliance',
          logo: 'Blog',
          contentTitle: '가습기',
          contentTitleEng: 'Humidifier',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'Top exposure keyword example',
          date: '2023. 02. 01',
          link: 'https://brunch.co.kr/@mayacrew/37',
        },
        {
          img: 'IT코딩교육플렛폼',
          category: 'IT/교육',
          categoryEng: 'IT/education',
          logo: 'TikTok',
          contentTitle: '서비스 홍보형',
          contentTitleEng: 'Service (promotional type)',
          contentDescription: '코딩 교육 플랫폼 마케팅 사례',
          contentDescriptionEng: 'example of ed. platform marketing',
          date: '2023. 03. 16',
          link: 'https://brunch.co.kr/@mayacrew/55',
        },
        {
          img: '설선물',
          category: '매장',
          categoryEng: 'season',
          logo: 'Blog',
          contentTitle: '설선물',
          contentTitleEng: 'Lunar New Year Gift',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2023. 01. 31',
          link: 'https://brunch.co.kr/@mayacrew/35',
        },
        {
          img: '오미자믹스마케팅사례',
          category: '식품',
          categoryEng: 'food',
          logo: 'Youtube',
          contentTitle: '제품 홍보형',
          contentTitleEng: 'Product (promotional type)',
          contentDescription: '오미자 믹스 마케팅 사례',
          contentDescriptionEng: 'schisandra mix marketing example',
          date: '2022. 10. 13',
          link: 'https://brunch.co.kr/@mayacrew/19',
        },
        {
          img: '성수카페',
          category: '매장',
          categoryEng: 'place',
          logo: 'Blog',
          contentTitle: '성수 카페',
          contentTitleEng: 'Seongsu Cafe',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2022. 10. 26',
          link: 'https://brunch.co.kr/@mayacrew/24',
        },
        {
          img: '여성패딩',
          category: '의류',
          categoryEng: 'clothing',
          logo: 'Blog',
          contentTitle: '여성패딩',
          contentTitleEng: 'Female Down Jacket',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2023. 02. 10',
          link: 'https://brunch.co.kr/@mayacrew/42',
        },
        {
          img: '밀키트',
          category: '식품',
          categoryEng: 'food',
          logo: 'Blog',
          contentTitle: '밀키트',
          contentTitleEng: 'Meal Kit',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2023. 03. 08',
          link: 'https://brunch.co.kr/@mayacrew/38',
        },
        {
          img: '미스트',
          category: '뷰티',
          categoryEng: 'beauty',
          logo: 'Blog',
          contentTitle: '미스트',
          contentTitleEng: 'Mist',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2023. 03. 08',
          link: 'https://brunch.co.kr/@mayacrew/53',
        },
        {
          img: '넷플릭스가격',
          category: 'IT',
          categoryEng: 'IT',
          logo: 'Blog',
          contentTitle: '넷플릭스 가격',
          contentTitleEng: 'Netflix Price',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2022. 10. 18',
          link: 'https://brunch.co.kr/@mayacrew/20',
        },
        {
          img: '제주렌트카',
          category: '여행',
          categoryEng: 'travel',
          logo: 'Blog',
          contentTitle: '제주 렌트카',
          contentTitleEng: 'Jeju Rent Car',
          contentDescription: '키워드 상위노출 사례',
          contentDescriptionEng: 'keyword top exposure example',
          date: '2023. 02. 24',
          link: 'https://brunch.co.kr/@mayacrew/49',
        },
      ],
      selected: 0,
      items: [],
      slickOptions: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1500,
        variableWidth: true,
        centerMode: true,
        centerPadding: '1px',
        focusOnSelect: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              autoplay: true,
              autoplaySpeed: 1500,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  async created() {
    try {
      const { data } = await this.axios('https://ipapi.co/json/');
      console.log('IP data');
      console.log(data);
      this.lang = data.country === 'KR' ? 'KR' : 'ENG';
    } catch (error) {
      console.log('GET https://ipapi.co/json/ Error');
      console.log(error);
      console.log(error.message);
    }
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    window.addEventListener('resize', () => {
      this.image =
        window.innerWidth > 1039
          ? 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fchart-desktop.png?alt=media&token=e9a9baae-a6ae-4017-b9d1-3229048483ac'
          : 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fchart-mobile.png?alt=media&token=4a9198e0-65fd-4882-a1e3-a3b2f3e483ed';
    });

    let pipedriveScript = document.createElement('script');
    pipedriveScript.setAttribute('src', 'https://webforms.pipedrive.com/f/loader');
    document.head.appendChild(pipedriveScript);

    // const scene1_1 = this.$scrollmagic.scene({
    //   triggerElement: '#trigger0',
    //   duration: 100,
    //   // triggerHook: 'onEnter',
    //   // reverse: true
    // })
    // // .setTween('#row1-1', {
    // //   css: {
    // //     opacity: 1,
    // //     y: 0
    // //   }
    // // })
    // .reverse(true)
    // .addIndicators({
    //   name: "1"
    // })

    const scene1_1 = this.$scrollmagic
      .scene({
        triggerElement: '#trigger1_1',
        duration: this.$refs.descriptionRow.clientHeight + 600 + 'px',
        offset: '-600px',
      })
      .setClassToggle('#row1-1', 'fade-up-active');

    const scene1_2 = this.$scrollmagic
      .scene({
        triggerElement: '#trigger1_2',
        duration: this.$refs.descriptionRow.clientHeight + 'px',
      })
      .setClassToggle('#row1-2', 'fade-up-active');

    const scene1_3 = this.$scrollmagic
      .scene({
        triggerElement: '#trigger1_3',
        duration: '100%',
      })
      .setClassToggle('#row1-3', 'fade-up-active');

    const scene2_1 = this.$scrollmagic
      .scene({
        triggerElement: '#trigger2_1',
        duration: this.$refs.descriptionRow.clientHeight + 600 + 'px',
        offset: '-600px',
      })
      .setClassToggle('#row2-1', 'fade-up-active');

    const scene2_2 = this.$scrollmagic
      .scene({
        triggerElement: '#trigger2_2',
        duration: this.$refs.descriptionRow.clientHeight + 'px',
      })
      .setClassToggle('#row2-2', 'fade-up-active');

    const scene2_3 = this.$scrollmagic
      .scene({
        triggerElement: '#trigger2_3',
        duration: '100%',
      })
      .setClassToggle('#row2-3', 'fade-up-active');

    this.$scrollmagic.addScene(scene1_1);
    this.$scrollmagic.addScene(scene1_2);
    this.$scrollmagic.addScene(scene1_3);

    this.$scrollmagic.addScene(scene2_1);
    this.$scrollmagic.addScene(scene2_2);
    this.$scrollmagic.addScene(scene2_3);

    this.$scrollmagic.attachTo(this.$refs.Element);
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}

::v-deep .v-expansion-panel__header {
  padding: 0px;
}

::v-deep tr.v-data-table__expanded.v-data-table__expanded__row > td {
  border-bottom: none !important;
}

::v-deep .container {
  max-width: 1040px;
  @media only screen and (max-width: 959px) {
    // height: none;
    max-width: 100%;
  }
}

::v-deep .v-dialog {
  border-radius: 24px;
}

* {
  font-family: 'Pretendard';
}

.matching {
  width: 100vw;
  // background-image: url('../../assets/img/landing/matching/hero_background_tinified-desktop.png');
  background: #fff;
  background-size: contain;
  background-position: center;
  background-size: cover;
  font-size: 27px;
  // @media only screen and (max-width: 959px) {
  //   background-image: url('../../assets/img/landing/matching/hero_background_tinified-mobile.png');
  // }
  // height: 694px;
  height: 80vh;
  min-height: 694px;
  @media only screen and (max-width: 959px) {
    // height: none;
    height: 720px;
  }
}

.container {
  width: 1040px;
}

.container--description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  min-height: 640px;
  max-height: 800px !important;
  @media only screen and (max-width: 959px) {
    // height: none;
    justify-content: start;
    height: 500px;
    min-height: 200px;
    padding: 24px 24px;
  }
}

.container--sticky {
  display: flex;
  align-items: center;
  position: sticky;
  top: 10vh;
  // top:0;
  // bottom: 20vh;
  height: 60vh;
  min-height: 640px;
  // @media only screen and (max-width: 959px) {
  //   display: none;
  // }
}

.image-background {
  position: relative;
  width: 580px;
  height: 480px;
  background: radial-gradient(141.32% 100% at 50% 100%, #2493f8 0%, #1469cc 84.09%);
  border-radius: 16px;
  justify-content: center;
  overflow: hidden;
}
.image-background--mobile {
  position: relative;
  width: 100%;
  height: 100%;
  background: radial-gradient(141.32% 100% at 50% 100%, #2493f8 0%, #1469cc 84.09%);
  border-radius: 12px;
  justify-content: '';
  overflow: hidden;
}
.image-background * {
  transition-property: opacity transform;
  transition-duration: 600ms;
  transform: translate(0, 32px);
  opacity: 0;
}

.manager-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  color: #1b2b43;
}

.manager-icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  background-color: #f5f5f5;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  overflow: hidden;
}

.manager-icon-circle--mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  background-color: #f5f5f5;
  width: 72px;
  height: 72px;
  border-radius: 80px;
  overflow: hidden;
}
.pipedrive-request {
  background-color: white;
  border-radius: 24px;
  width: 200px;
}

.hero-title--matching {
  color: #0f1633;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  @media only screen and (max-width: 959px) {
    font-size: 27px;
    line-height: 40px;
  }
}

.hero-subtitle--matching {
  color: #21232e;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  @media only screen and (max-width: 959px) {
    font-size: 16px;
    line-height: 28px;
  }
}

.subtitle--naver {
  color: #1bb000;
}
.subtitle--youtube {
  color: #ee0000;
}
.subtitle--matching {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  @media only screen and (max-width: 959px) {
    font-size: 13px;
    line-height: 32px;
  }
}
.title--matching {
  color: #21232e;
  font-weight: 800;
  font-size: 30px;
  line-height: 42px;
  @media only screen and (max-width: 959px) {
    font-size: 24px;
    line-height: 32px;
  }
}
.body--matching {
  color: #21232e;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  @media only screen and (max-width: 959px) {
    font-size: 14px !important;
    line-height: 24px;
  }
}

.subtitle--description {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
}
.title--description {
  color: #21232e;
  font-weight: 700;
  font-size: 27px;
  line-height: 36px;
  @media only screen and (max-width: 959px) {
    font-size: 21px;
    line-height: 32px;
  }
}
.body--description {
  color: #21232e;
  -webkit-line-clamp: 3;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  @media only screen and (max-width: 959px) {
    font-size: 14px !important;
    line-height: 24px;
    -webkit-line-clamp: 2;
  }
}

.row-image {
  width: 100%;
  padding: 0;
  img {
    width: 100%;
    padding: auto 0;
  }
  @media only screen and (max-width: 959px) {
    margin: 0 0 32px;
    height: none;
    padding: 0 !important;
  }
}

#row-1-background img {
  @media only screen and (max-width: 959px) {
    width: 458px;
    margin-left: 8px;
  }
}

#row-1-foreground img {
  @media only screen and (max-width: 959px) {
    width: 282px;
  }
}

#row-3 {
  max-width: 100%;
  img {
    @media only screen and (max-width: 959px) {
      margin: 0 auto;
    }
  }
}

#row-4 img {
  @media only screen and (max-width: 959px) {
    margin: 0 auto;
  }
}

#companies img {
  @media only screen and (max-width: 959px) {
    max-width: 360px;
  }
}

.filter {
  background-color: white;
}

.active {
  background-color: #1e69d4 !important;
}

.row-pointer:hover {
  cursor: pointer;
}

.description--circle {
  position: absolute;
  width: 160px;
  height: 160px;
  padding-bottom: 8px;
  border-radius: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  span {
    font-size: 22px;
    line-height: 36px;
  }
}

.description--circle--mobile {
  width: 140px;
  height: 140px;
  border-radius: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  span {
    font-size: 18px;
    line-height: 24px;
  }
}
.cta {
  font-size: 18px;
  font-weight: 500;
  min-height: 60px;
  min-width: 216px;
  line-height: 24px;
  letter-spacing: 0.3px;
  background: #f53c4b;
  border-radius: 16px;
  padding: 0 24px !important;
  box-shadow: 0px 16px 32px 0px #0000001a;
  @media only screen and (max-width: 959px) {
    font-size: 16px;
    min-height: 52px;
    min-width: 160px;
    box-shadow: 0px 6px 16px 0px #0000001a;
  }
}

.prevArrow {
  color: #808080;
  padding: 8px 12px 8px 6px;
  border-radius: 40px;
  background-color: #fbfbfb;
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
}
.nextArrow {
  color: #808080;
  padding: 8px 6px 8px 12px;
  border-radius: 40px;
  background-color: #fbfbfb;
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
}

.tabs {
  padding: 0 8px;
  letter-spacing: 0.8px !important;
  font-size: 16px;
  line-height: 24px;
  min-width: 16px;
  @media only screen and (max-width: 959px) {
    font-size: 14px;
  }
}

.tabs-active {
  background-color: rgba($color: #ffffff, $alpha: 0);
  font-weight: 700;
}

.bg-color--white {
  background-color: #fff;
}

.bg-color--gray {
  background-color: #fdfdfd;
}

.bg-color--blue-gradient {
  background: radial-gradient(141.32% 100% at 50% 100%, #2493f8 0%, #1469cc 84.09%);
}

.logos-desktop div {
  height: 56px;
  width: 100vw;
}

.logos-mobile div {
  height: 34px;
  width: 100vw;
}

.marquee-desktop-1 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-desktop-1.png?alt=media&token=160fe420-a91b-455f-9fbf-b6e737321d6a')
    0 center / 2240px repeat-x;
  animation: movebg-desktop 32s linear infinite;
}
.marquee-desktop-2 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-desktop-2.png?alt=media&token=39e97a02-a25e-4368-9912-3e9775db04c6')
    0 center / 2240px repeat-x;
  animation: movebg-desktop 80s linear infinite;
  animation-direction: reverse;
}
.marquee-desktop-3 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-desktop-3.png?alt=media&token=df246159-96eb-4d30-ba66-c4fde351ae80')
    0 center / 2240px repeat-x;
  animation: movebg-desktop 90s linear infinite;
}
.marquee-desktop-4 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-desktop-4.png?alt=media&token=b9e8b222-3723-4996-a302-7375ae65a2bf')
    0 center / 2240px repeat-x;
  animation: movebg-desktop 100s linear infinite;
  animation-direction: reverse;
}

.marquee-mobile-1 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-mobile-1.png?alt=media&token=f967c503-6382-4830-8d4d-4d040a4f2752')
    0 center / 1288px repeat-x;
  animation: movebg-mobile 24s linear infinite;
}
.marquee-mobile-2 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-mobile-2.png?alt=media&token=21f2c886-8faf-46e2-bb4a-1ed2ec02de1f')
    0 center / 1288px repeat-x;
  animation: movebg-mobile 70s linear infinite;
  animation-direction: reverse;
}
.marquee-mobile-3 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-mobile-3.png?alt=media&token=dc53ee23-54dd-4490-abe1-51ddf116566d')
    0 center / 1288px repeat-x;
  animation: movebg-mobile 80s linear infinite;
}
.marquee-mobile-4 {
  background: url('https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-2024-mobile-4.png?alt=media&token=0819d0c5-b5d8-45af-9504-99a02d7b04e6')
    0 center / 1288px repeat-x;
  animation: movebg-desktop 90s linear infinite;
  animation-direction: reverse;
}

@keyframes movebg-desktop {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -2240px center;
  }
}

@keyframes movebg-mobile {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1288px center;
  }
}

.fade-up-active {
  opacity: 1 !important;
  transform: translate(0, -32px);
}

.fade-up-disable {
  opacity: 0 !important;
  transform: translate(0, 32px);
}

#row1-1 {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row1-1-desktop.png);
  background-size: cover;
  opacity: 0;
}

.row1-1-eng {
  background-image: url(../../assets/img/landing/matching/yt-row1-1-desktop-eng.png) !important;
}

#row1-2 {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row1-2-desktop.png);
  background-size: cover;
  opacity: 0;
}

.row1-2-eng {
  background-image: url(../../assets/img/landing/matching/yt-row1-2-desktop-eng.png) !important;
}

#row1-3 {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row1-3-desktop.png);
  background-size: cover;
  opacity: 0;
}

.row1-3-eng {
  background-image: url(../../assets/img/landing/matching/yt-row1-3-desktop-eng.png) !important;
}

#row2-1 {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row2-1-desktop.png);
  background-size: cover;
  opacity: 0;
}

.row2-1-eng {
  background-image: url(../../assets/img/landing/matching/yt-row2-1-desktop-eng.png) !important;
}

#row2-2 {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row2-2-desktop.png);
  background-size: cover;
  opacity: 0;
}

.row2-2-eng {
  background-image: url(../../assets/img/landing/matching/yt-row2-2-desktop-eng.png) !important;
}

#row2-3 {
  position: absolute;
  top: 32px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row2-3-desktop.png);
  background-size: cover;
  opacity: 0;
}

.row2-3-eng {
  background-image: url(../../assets/img/landing/matching/yt-row2-3-desktop-eng.png) !important;
}

#row1-1-mobile {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row1-1-mobile.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
}

.row1-1-mobile-eng {
  background-image: url(../../assets/img/landing/matching/yt-row1-1-mobile-eng.png) !important;
}

#row1-2-mobile {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row1-2-mobile.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
}

.row1-2-mobile-eng {
  background-image: url(../../assets/img/landing/matching/yt-row1-2-mobile-eng.png) !important;
}

#row1-3-mobile {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row1-3-mobile.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
}

.row1-3-mobile-eng {
  background-image: url(../../assets/img/landing/matching/yt-row1-3-mobile-eng.png) !important;
}

#row2-1-mobile {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row2-1-mobile.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
}

.row2-1-mobile-eng {
  background-image: url(../../assets/img/landing/matching/yt-row2-1-mobile-eng.png) !important;
}

#row2-2-mobile {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row2-2-mobile.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
}

.row2-2-mobile-eng {
  background-image: url(../../assets/img/landing/matching/yt-row2-2-mobile-eng.png) !important;
}

#row2-3-mobile {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/landing/matching/yt-row2-3-mobile.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
}

.row2-3-mobile-eng {
  background-image: url(../../assets/img/landing/matching/yt-row2-3-mobile-eng.png) !important;
}

/*  Animation starts here */
[data-aos='fade-up-small'] {
  transform: translate(0, 48px);
  opacity: 0;
  transition-property: opacity transform;
}
/*  Animation ends here */
[data-aos='fade-up-small'].aos-animate {
  transform: translate(0, 0);
  opacity: 1;
}

.container--indicator {
  padding-top: 24px;
  display: flex;
  gap: 8px;
}

.indicator--disabled {
  width: 28px;
  height: 3px;
  border-radius: 3px;
  background-color: #f5f5f5;
}
.indicator--naver {
  width: 28px;
  height: 3px;
  border-radius: 3px;
  background-color: #1bbb00;
}
.indicator--youtube {
  width: 28px;
  height: 3px;
  border-radius: 3px;
  background-color: #ee0000;
}

.item {
  box-sizing: border-box;
  width: 300px;
  min-width: 300px;
  text-align: initial;

  .top {
    height: 360px;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%);
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    background-size: cover;
  }

  .bottom {
    background: #ffffff;
    box-shadow: 0px -16px 32px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 12px 12px;
    box-sizing: border-box;
    padding: 22px 28px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      span {
        box-sizing: border-box;
        padding: 6px 12px;
        border: 2px solid #757575;
        border-radius: 40px;
        font-weight: 600;
        font-size: 14px;
        color: #757575;
        line-height: 100%;
      }

      img {
        width: 26px;
        height: 30px;
      }
    }

    .contents {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 4px;

      .description {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .date {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #c2c2c2;
    }
  }
}
</style>
